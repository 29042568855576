import React, { useRef, useEffect, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { gsap } from "gsap"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { TweenMax, TimelineMax, Power3 } from "gsap"
import { ScrollObserver, ScrollScene } from "scrollscene"
// import "../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"

import facebook from "../images/linkedin_gold.png"
import instagram from "../images/github_gold.png"

const getHomeContent = graphql`
  {
    homePageContent: allWordpressPost(
      filter: { slug: { eq: "home-page-content" } }
    ) {
      edges {
        node {
          acf {
            banner {
              header
              content
            }
          }
        }
      }
    }
    heroBgImg: allWordpressWpHero {
      edges {
        node {
          acf {
            home_hero {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    profileImg: file(relativePath: { eq: "profile5-greyscale.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    homeHeroBgImg: file(relativePath: { eq: "banner-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bannerBgImg: file(relativePath: { eq: "birds.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    featuredPosts: allWordpressWpFeatured {
      edges {
        node {
          slug
          path
          title
          id
          acf {
            title
            image_left {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            image_right {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            excerpt
            link
          }
        }
      }
    }
  }
`

const IndexPage = () => {
  const {
    featuredPosts,
    heroBgImg,
    homeHeroBgImg,
    profileImg,
    homePageContent,
    bannerBgImg,
  } = useStaticQuery(getHomeContent)

  const bgImage =
    heroBgImg.edges[0].node.acf.home_hero.localFile.childImageSharp.fluid
  const profileImage = profileImg.childImageSharp.fluid
  const homeHeroBgImage = homeHeroBgImg.childImageSharp.fluid
  const bannerHeader = homePageContent.edges[0].node.acf.banner.header
  const bannerContent = homePageContent.edges[0].node.acf.banner.content
  const bannerBgImage = bannerBgImg.childImageSharp.fluid

  let headerContainer = useRef(null)
  let headerLogo = useRef(null)
  let headerMenu = useRef(null)

  let loadTextBox = useRef(null)
  let loadLetterY = useRef(null)
  let loadLetterT = useRef(null)
  let logoItem = useRef(null)
  let name = useRef(null)
  let description = useRef(null)
  let portfolioBtn = useRef(null)
  let social = useRef(null)
  let profile = useRef(null)
  let profileCover = useRef(null)
  let bg = useRef(null)
  let loadCont = useRef(null)
  let circle = useRef(null)
  let aboutTitle = useRef(null)
  let aboutText = useRef(null)

  useEffect(() => {
    //Home Page GSAP Animations
    const homePageAnimation = gsap.timeline()
    const aboutAnimation = gsap.timeline({ paused: true })
    const portfolioBottomAnimation = gsap.timeline({ paused: true })

    homePageAnimation
      .set([headerContainer.current], {
        visibility: "visible",
        onComplete: function() {
          bodyFixed()
        },
      })
      .to(loadTextBox, {
        autoAlpha: 1,
        delay: 0.3,
      })
      .from(
        [loadLetterY, loadLetterT],
        {
          duration: 0.3,
          delay: .8,
          autoAlpha: 0,
          y: 20,
          stagger: 0.1,
          ease: "power3.inOut",
        },
        "-=.9"
      )
      .to([loadLetterY, loadLetterT], {
        duration: 0.3,
        delay: .8,
        autoAlpha: 0,
        y: -20,
        stagger: 0.1,
        ease: "power3.inOut",
        onComplete: function() {
          disableBodyFixed()
        },
      })
      .to(loadTextBox, {
        autoAlpha: 0,
      })
      .to(loadCont, {
        duration: 1.1,
        scaleX: 0,
        transformOrigin: "right",
        ease: "power3.inOut",
        force3D: true,
      })
      .from(
        [headerLogo.current, headerMenu.current],
        {
          duration: 0.5,
          y: 10,
          autoAlpha: 0,
          ease: "power3.inOut",
          stagger: 0.2,
        },
        "-=0.7"
      )
      .from(
        circle,
        {
          duration: 1,
          x: 50,
          autoAlpha: 0,
          ease: "power3.inOut",
          force3D: true,
        },
        "<-0.2"
      )
      .set(profileCover, {
        visibility: "visible",
      })
      .from(profileCover, {
        duration: 0.6,
        scaleX: 0,
        transformOrigin: "left",
        ease: "cubic-bezier(.59,.08,.385,1)",
        force3D: true,
      })
      .from(profile, 0, {
        autoAlpha: 0,
        force3D: true,
        immediateRender: false,
      })
      .to(profileCover, {
        duration: 0.6,
        scaleX: 0,
        transformOrigin: "right",
        ease: "cubic-bezier(.59,.08,.385,1)",
        force3D: true,
      })
      .from(
        circle,
        {
          duration: 1,
          ease: "power3.inOut",
          rotation: 360,
          force3D: true,
        },
        "-=0.8"
      )
      .to(
        circle,
        {
          duration: 1,
          ease: "power3.inOut",
          opacity: 0.1,
          force3D: true,
        },
        "<"
      )
      .from(
        [description, name, portfolioBtn, social],
        {
          autoAlpha: 0,
          y: 8,
          ease: "power1.easeOut",
          stagger: 0.05,
          force3D: true,
        },
        "-=.8"
      )

    aboutAnimation.from([aboutTitle, aboutText], {
      autoAlpha: 0,
      y: 30,
      ease: "power3.inOut",
      stagger: {
        amount: 0.5,
      },
    })

    new ScrollScene({
      triggerElement: ".about",
      gsap: {
        timeline: aboutAnimation,
      },
      scene: {
        reverse: false,
      },
      destroyImmediately: true,
    })

    portfolioBottomAnimation.from(
      [".cta-bottom__header", ".cta-bottom__link"],
      {
        autoAlpha: 0,
        y: 30,
        ease: "power3.inOut",
        stagger: {
          amount: 0.1,
        },
      }
    )

    new ScrollScene({
      triggerElement: ".cta-bottom",
      gsap: {
        timeline: portfolioBottomAnimation,
      },
      scene: {
        reverse: false,
        triggerHook: 0.75,
      },
      destroyImmediately: true,
    })

    const bodyFixed = e => {
      document.querySelector("body").style.position = "fixed"
    }
    const disableBodyFixed = e => {
      document.querySelector("body").style.position = "initial"
    }
  }, [])

  useEffect(() => {
    // Projects Image Reveal Animation Class
    let revealRow = Array.prototype.slice.call(
      document.querySelectorAll(".animateProjectReveal")
    )
    let self = this

    revealRow.forEach(function(self) {
      const projectRevealAnimation = gsap.timeline({ paused: true })

      projectRevealAnimation
        .from(self.querySelector(".portfolio__img-cover--left"), 0.5, {
          scaleX: 0,
          transformOrigin: "left",
          ease: "cubic-bezier(.59,.08,.385,1)",
        })
        .to(self.querySelector(".portfolio__img--left"), {
          duration: 0,
          autoAlpha: 1,
          rotation: 0.01,
        })
        .to(self.querySelector(".portfolio__img-cover--left"), 0.5, {
          scaleX: 0,
          transformOrigin: "right",
          ease: "cubic-bezier(.59,.08,.385,1)",
          rotation: 0.01,
        })
        .from(
          self.querySelector(".portfolio__img-cover--right"),
          0.5,
          {
            scaleX: 0,
            transformOrigin: "left",
            ease: "cubic-bezier(.59,.08,.385,1)",
          },
          "-=.8"
        )
        .to(self.querySelector(".portfolio__img--right"), {
          duration: 0,
          autoAlpha: 1,
          rotation: 0.01,
        })
        .to(self.querySelector(".portfolio__img-cover--right"), 0.5, {
          scaleX: 0,
          transformOrigin: "right",
          ease: "cubic-bezier(.59,.08,.385,1)",
          rotation: 0.01,
        })

      const projectImgScrollScene = new ScrollScene({
        triggerElement: self,
        gsap: {
          timeline: projectRevealAnimation,
        },
        whenVisible: "15%",
        scene: {
          reverse: false,
        },
      })

      projectImgScrollScene.Scene.on("enter", function(e) {
        console.log("img scene entered")
      })
    })
  }, [])

  useEffect(() => {
    console.log("content useEffect")
    // Projects Content Reveal Animation Class
    let revealProjectsContentRow = Array.prototype.slice.call(
      document.querySelectorAll(".portfolio__text-wrap")
    )
    let self = this

    revealProjectsContentRow.forEach(function(self) {
      const projectContentAnimation = gsap.timeline({ paused: true })

      projectContentAnimation.from(
        [
          self.querySelector(".portfolio__subheading"),
          self.querySelector(".portfolio__text"),
          self.querySelector(".portfolio__link"),
        ],
        {
          autoAlpha: 0,
          y: 30,
          ease: "power3.easeOut",
          stagger: {
            amount: 0.6,
          },
        }
      )

      const projectContentScene = new ScrollScene({
        triggerElement: self,
        gsap: {
          timeline: projectContentAnimation,
        },
        scene: {
          reverse: false,
          triggerHook: 0.75,
        },
      })

      projectContentScene.Scene.on("enter", function(e) {
        console.log("content scene entered")
      })

      // destory or unmount
      return () => {
        projectContentScene.destroy()
        console.log("destroyed")
      }
    })
  }, [])

  return (
    <Layout
      ref={{
        headerLogo: headerLogo,
        headerMenu: headerMenu,
        headerContainer: headerContainer,
      }}
    >
      <SEO title="Home" />
      <div ref={el => (loadCont = el)} className="home-hero__load-container">
        <div ref={el => (loadTextBox = el)} className="home-hero__load-text">
          <h1 ref={el => (loadLetterY = el)}>y</h1>
          <h1 ref={el => (loadLetterT = el)}>t</h1>
        </div>
      </div>
      <section className="full-width-container section-home-hero">
        <div className="home-hero__background-image-wrapper">
          <BackgroundImage fluid={homeHeroBgImage}></BackgroundImage>
        </div>
        <div className="fixed-width-container home-hero">
          <div ref={el => (bg = el)} className="home-hero__bg"></div>
          <div ref={el => (circle = el)} className="home-hero__bgImg">
            <Img fluid={bgImage} alt="" />
          </div>
          <div
            ref={el => (profile = el)}
            className="home-hero__profile-img-wrap"
          >
            <Img fluid={profileImage} className="home-hero__profile-image" />
            <div
              ref={el => (profileCover = el)}
              className="home-hero__profile-image--cover"
            ></div>
          </div>
          <div className="home-hero__content">
            <p
              ref={el => (description = el)}
              className="home-hero__description"
            >
              web developer
              <br />
              houston, texas
            </p>
            <h2 className="heading-secondary">
              <span className="home-hero__name" ref={el => (name = el)}>
                yuta tamura
              </span>
            </h2>
            <a
              ref={el => (portfolioBtn = el)}
              className="home-hero__link btn-line-anim"
              href="#portfolio"
            >
              projects &#9660;
            </a>
            <div ref={el => (social = el)} className="home-hero__social">
              <a
                className="home-hero__social--link"
                href="https://www.linkedin.com/in/yuta-tamura"
                target="_blank"
              >
                <img src={facebook} alt="" />
              </a>
              <a
                className="home-hero__social--link"
                href="https://github.com/yutaatamura"
                target="_blank"
              >
                <img src={instagram} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="section-about full-width-container">
        <div className="about__background-image-wrapper">
          <Img fluid={bannerBgImage}></Img>
        </div>
        <div className="about fixed-width-container">
          <div className="about__row">
            <h1
              className="heading-primary about__heading"
              ref={el => (aboutTitle = el)}
            >
              {bannerHeader}
            </h1>
            <p className="about__content" ref={el => (aboutText = el)}>
              {bannerContent}
            </p>
          </div>
        </div>
      </section>
      <section
        id="portfolio"
        className="section-portfolio full-width-container"
      >
        <div className="portfolio fixed-width-container">
          <h2 className="heading-secondary portfolio__header">
            Recent Projects
          </h2>
          {featuredPosts.edges.map(({ node }) => {
            const title = node.acf.title
            const imgLeft = node.acf.image_left.localFile.childImageSharp.fluid
            const imgRight =
              node.acf.image_right.localFile.childImageSharp.fluid
            const excerpt = node.acf.excerpt
            const link = node.acf.link
            return (
              <div
                className="portfolio__row  fixed-width-container animateProjectReveal"
                key={node.id}
              >
                <div className="portfolio__bg-layer"></div>
                <div className="portfolio__content">
                  <div className="portfolio__img-wrap">
                    <Img
                      fluid={imgRight}
                      className="portfolio__img portfolio__img--right"
                      alt=""
                    />
                    <div className="portfolio__img-cover portfolio__img-cover--right"></div>
                  </div>
                  <div className="portfolio__text-wrap portfolio__project1-text">
                    <h2 className="heading-secondary portfolio__subheading">
                      {title}
                    </h2>
                    <p className="portfolio__text">{excerpt}</p>
                    <AniLink
                      cover
                      direction="up"
                      bg="#000"
                      to={link}
                      className="portfolio__link btn-line-anim"
                    >
                      Explore
                    </AniLink>
                  </div>
                </div>
                <div className="portfolio__img-col">
                  <div className="portfolio__img-wrap">
                    <Img
                      fluid={imgLeft}
                      className="portfolio__img portfolio__img--left"
                      alt=""
                    />
                    <div className="portfolio__img-cover portfolio__img-cover--left"></div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <section className="section-cta-bottom full-width-container">
        <div className="cta-bottom__background-image-wrapper">
          <Img fluid={bannerBgImage}></Img>
        </div>
        <div className="cta-bottom fixed-width-container">
          <div className="cta-bottom__row">
            <h2 className="cta-bottom__header">Check out some of my work!</h2>
            <AniLink
              className="cta-bottom__link"
              to="/portfolio"
              cover
              direction="down"
              bg="#000"
              top="exit"
            >
              Portfolio
            </AniLink>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
